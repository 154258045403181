// Add non schema related enums here
export const DependencyFormat = {
  NONE: "NONE",
  DETACHED: "DETACHED",
  FOLLOWUP: "FOLLOWUP",
};
export type DependencyFormat =
  (typeof DependencyFormat)[keyof typeof DependencyFormat];

export const TableCustomColumns = {
  CHECKBOX: "CHECKBOX",
  SELECT: "SELECT",
  MEMO: "MEMO",
};
export type TableCustomColumns =
  (typeof TableCustomColumns)[keyof typeof TableCustomColumns];

export const TablePatientInfoColumns = {
  NAME: "NAME",
  NAME_PRONUNCIATION: "NAME_PRONUNCIATION",
  CARD_NUMBER: "CARD_NUMBER",
  DATE_OF_BIRTH: "DATE_OF_BIRTH",
  SEX: "SEX",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL: "EMAIL",
};
export type TablePatientInfoColumns =
  (typeof TablePatientInfoColumns)[keyof typeof TablePatientInfoColumns];

export const AppointmentKeys = {
  MENU: "menuName",
  SUBMENU: "submenuName",
  DATE: "startDate",
  TIME: "formattedStartTime",
  NAME: "fullName",
  KANA: "fullNameKana",
  DOB: "dob",
  SEX: "sex",
  CARD: "cardNumber",
  TEL: "tel",
  EMAIL: "email",
  CREATEDAT: "timestamp",
  STATUS: "statusString",
  FORM: "form",
  CHECK: "check",
};
export type AppointmentKeys =
  (typeof AppointmentKeys)[keyof typeof AppointmentKeys];

export const CsvFormSubmissionPatientInfoKeys = {
  SUBMIT_DATE: "createdAtDate",
  SUBMIT_TIME: "createdAtTime",
  FORM_TITLE: "formLabel",
  PATIENT_NAME: "name",
  PATIENT_NAME_KANA: "kana",
  NEW_OR_RETURN_PATIENT: "newPatient",
  PATIENT_CARD_NUMBER: "cardNumber",
  PATIENT_SEX: "sex",
  PATIENT_DOB: "dob",
  PATIENT_AGE: "age",
  PATIENT_HEIGHT: "height",
  PATIENT_WEIGHT: "weight",
  PATIENT_BMI: "bmi",
  PATIENT_CELL_NUMBER: "cellPhone",
  PATIENT_HOME_NUMBER: "homePhone",
  PATIENT_ADDRESS: "address",
  VISIT_REASON: "formattedVisitReasonAnswer",
  APPOINTMENT_DATE_AND_TIME: "appointmentDatetime",
};
export type CsvFormSubmissionPatientInfoKeys =
  (typeof CsvFormSubmissionPatientInfoKeys)[keyof typeof CsvFormSubmissionPatientInfoKeys];

export const CsvPatientInfoHeaderValues = {
  SUBMIT_DATE: "送信日",
  SUBMIT_TIME: "送信時刻",
  FORM_TITLE: "問診票",
  PATIENT_NAME: "氏名",
  PATIENT_NAME_KANA: "フリガナ",
  NEW_OR_RETURN_PATIENT: "再診",
  PATIENT_CARD_NUMBER: "診察券",
  PATIENT_SEX: "性別",
  PATIENT_DOB: "生年月日",
  PATIENT_AGE: "年齢",
  PATIENT_HEIGHT: "身長",
  PATIENT_WEIGHT: "体重",
  PATIENT_BMI: "BMI",
  PATIENT_CELL_NUMBER: "携帯電話番号",
  PATIENT_HOME_NUMBER: "自宅電話番号",
  PATIENT_ADDRESS: "住所",
  VISIT_REASON: "来院理由",
  APPOINTMENT_DATE_AND_TIME: "予約日時",
};
export type CsvPatientInfoHeaderValues =
  (typeof CsvPatientInfoHeaderValues)[keyof typeof CsvPatientInfoHeaderValues];

export const ExportAllOrByMenu = {
  ALL: "全てのメニュー (患者情報のみ)",
  BY_MENU: "指定メニュー (患者情報・回答データ)",
};
export type ExportAllOrByMenu =
  (typeof ExportAllOrByMenu)[keyof typeof ExportAllOrByMenu];

export const BatchCreateIntervals = {
  EVERYDAY: "day",
  EVERYWEEK: "week",
  EVERYMONTH: "month",
};

export type BatchCreateIntervals =
  (typeof BatchCreateIntervals)[keyof typeof BatchCreateIntervals];

export const ExportSubmissionsFileFormats = {
  CSV: "CSV",
  XLSX: "XLSX",
};
export type ExportSubmissionsFileFormats =
  (typeof ExportSubmissionsFileFormats)[keyof typeof ExportSubmissionsFileFormats];
